import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Table from './ReactTable';
import _ from 'lodash';
import db from './dexieDB';

const Styles = styled.div`
  padding: 1rem;
  padding-top: 86px;

  table {
    border-spacing: 0;
    border: 1px solid black;
    background-color: #ffffff;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function UpdateWariParticipant() {
  const [myData, setMyData] = React.useState([]);
  const [editingRow, setEditingRow] = React.useState(null);
  const [newRoomValue, setNewRoomValue] = React.useState('');

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://31.222.200.74:8080/auth/leaderboard/getAllWariParticipants');
        setMyData(response.data);
        await db.participants.bulkPut(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        const localData = await db.participants.toArray();
        setMyData(localData);
      }
    };
    fetchData();

    const handleSync = async () => {
      const syncItems = await db.syncQueue.toArray();
      for (let item of syncItems) {
        try {
          if (item.operation === 'updateStatus') {
            await axios.patch('http://31.222.200.74:8080/auth/leaderboard/updateStatus', item.data);
          } else if (item.operation === 'updateRoom') {
            await axios.patch('http://31.222.200.74:8080/auth/leaderboard/auth/leaderboard/updateRoom', item.data);
          }
          await db.syncQueue.delete(item.id);
        } catch (error) {
          console.error('Sync error:', error);
        }
      }
    };

    window.addEventListener('online', handleSync);
    return () => {
      window.removeEventListener('online', handleSync);
    };
  }, []);

  const handleStatusChange = async (row, field) => {
    const currentValue = row[field];
    const newValue = currentValue ? null : 'done';

    if (window.confirm(`Do you want to change the status of ${field} for ${row.attendeename}?`)) {
      try {
        await axios.patch('http://31.222.200.74:8080/auth/leaderboard/updateStatus', {
          bibno: row.bibno,
          field,
          value: newValue,
        });
        setMyData(prevData =>
          prevData.map(item =>
            item.bibno === row.bibno ? { ...item, [field]: newValue } : item
          )
        );
        alert('Status updated successfully.');
      } catch (error) {
        console.error('Error updating status:', error);
        await db.syncQueue.add({ operation: 'updateStatus', data: { bibno: row.bibno, field, value: newValue } });
        alert('Status update will be synced when online.');
      }
    }
  };

  const handleRoomDoubleClick = (row) => {
    setEditingRow(row.bibno);
    setNewRoomValue(row.room);
  };

  const handleRoomBlur = async (row) => {
    if (window.confirm('Are you sure you want to change the room number?')) {
      try {
        await axios.patch('http://31.222.200.74:8080/auth/leaderboard/updateRoom', {
          bibno: row.bibno,
          room: newRoomValue,
        });
        setMyData(prevData =>
          prevData.map(item =>
            item.bibno === row.bibno ? { ...item, room: newRoomValue } : item
          )
        );
        alert('Room number updated successfully.');
      } catch (error) {
        console.error('Error updating room number:', error,newRoomValue);
        await db.syncQueue.add({ operation: 'updateRoom', data: { bibno: row.bibno, room: newRoomValue } });
        alert('Room update will be synced when online.');
      }
    }
    setEditingRow(null);
  };

  const debouncedSetNewRoomValue = React.useCallback(
    _.debounce((value) => {
      setNewRoomValue(value);
    }, 300),
    []
  );

  const columns = React.useMemo(() => [
    {
      Header: 'Participant Info',
      columns: [
        {
          Header: 'Attendee Name',
          accessor: 'attendeename',
        },
        {
          Header: 'Mobile',
          accessor: 'mobile',
        },
        {
          Header: 'Room',
          accessor: 'room',
          disableGroupBy: true,
          Cell: ({ row }) => {
            if (editingRow === row.original.bibno) {
              return (
                <input
                  value={newRoomValue}
                  onChange={(e) => debouncedSetNewRoomValue(e.target.value)}
                  onBlur={() => handleRoomBlur(row.original)}
                />
              );
            }
            return (
              <span onDoubleClick={() => handleRoomDoubleClick(row.original)}>
                {row.original.room || 'No room Allocated'}
              </span>
            );
          }
        },
        {
          Header: 'BIB',
          accessor: 'bibno',
        },
        {
          Header: 'Jersey',
          accessor: 'jerseysize',
        },
        {
          Header: 'Gender',
          accessor: 'gender',
        }
      ],
    },
    {
      Header: 'Collection Status',
      columns: [
        {
          Header: 'BIB Collect',
          accessor: 'bibcollect',
          disableGroupBy: true,
          Cell: ({ row }) => (
            <a href="#" onClick={() => handleStatusChange(row.original, 'bibcollect')}>
              {row.original.bibcollect || 'Click to update'}
            </a>
          ),
        },
        {
          Header: 'Tshirt Collect',
          accessor: 'tshirtcollect',
          disableGroupBy: true,
          Cell: ({ row }) => (
            <a href="#" onClick={() => handleStatusChange(row.original, 'tshirtcollect')}>
              {row.original.tshirtcollect || 'Click to update'}
            </a>
          ),
        },
        {
          Header: 'Breakfast 1',
          accessor: 'breakfast1',
          disableGroupBy: true,
          Cell: ({ row }) => (
            <a href="#" onClick={() => handleStatusChange(row.original, 'breakfast1')}>
              {row.original.breakfast1 || 'Click to update'}
            </a>
          ),
        },
        {
          Header: 'Breakfast 2',
          accessor: 'breakfast2',
          disableGroupBy: true,
          Cell: ({ row }) => (
            <a href="#" onClick={() => handleStatusChange(row.original, 'breakfast2')}>
              {row.original.breakfast2 || 'Click to update'}
            </a>
          ),
        },
        {
          Header: 'Lunch',
          accessor: 'lunch',
          disableGroupBy: true,
          Cell: ({ row }) => (
            <a href="#" onClick={() => handleStatusChange(row.original, 'lunch')}>
              {row.original.lunch || 'Click to update'}
            </a>
          ),
        },
        {
          Header: 'Dinner',
          accessor: 'dinner',
          disableGroupBy: true,
          Cell: ({ row }) => (
            <a href="#" onClick={() => handleStatusChange(row.original, 'dinner')}>
              {row.original.dinner || 'Click to update'}
            </a>
          ),
        },
        {
          Header: 'Medal',
          accessor: 'medal',
          disableGroupBy: true,
          Cell: ({ row }) => (
            <a href="#" onClick={() => handleStatusChange(row.original, 'medal')}>
              {row.original.medal || 'Click to update'}
            </a>
          ),
        }
      ],
    },
  ], [editingRow, newRoomValue]);

  const data = React.useMemo(() => myData, [myData]);

  return (
    <Styles>
      <Table columns={columns} data={data} />
    </Styles>
  );
}

export default UpdateWariParticipant;
