// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import AllWariParticipants from './components/AllWariParticipants';
// import UpdateWariParticipant from './components/UpdateWariParticipant';

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/all" element={<AllWariParticipants />} />
//         <Route path="/update" element={<UpdateWariParticipant />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import AllWariParticipants from './components/AllWariParticipants';
// import UpdateWariParticipant from './components/UpdateWariParticipant';

// function App() {
//   return (
//     <Router>
//       <div>
//         <nav>
//           <ul>
//             <li>
//               <Link to="/all">All Wari Participants</Link>
//             </li>
//             <li>
//               <Link to="/update">Update Wari Participant</Link>
//             </li>
//           </ul>
//         </nav>

//         <Routes>
//           <Route path="/all" element={<AllWariParticipants />} />
//           <Route path="/update" element={<UpdateWariParticipant />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import AllWariParticipants from './components/AllWariParticipants';
import UpdateWariParticipant from './components/UpdateWariParticipant';
import styled from 'styled-components';

const Nav = styled.nav`
  padding: 1rem;
  background-color: #f0f0f0;
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 1rem;
  }
  li {
    display: inline;
  }
  a {
    text-decoration: none;
    color: #007bff;
  }
`;

const Container = styled.div`
  padding: 1rem;
`;

function App() {
  return (
    <Router>
      <div>
        <Nav>
          <ul>
            <li>
              <Link to="/all">All Wari Participants</Link>
            </li>
            <li>
              <Link to="/update">Update Wari Participant</Link>
            </li>
          </ul>
        </Nav>
        <Container>
          <Routes>
            <Route path="/all" element={<AllWariParticipants />} />
            <Route path="/update" element={<UpdateWariParticipant />} />
          </Routes>
        </Container>
      </div>
    </Router>
  );
}

export default App;
