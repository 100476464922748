import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Table from './ReactTable';
import db from './dexieDB';

const Styles = styled.div`
  padding: 1rem;
  padding-top: 86px;

  table {
    border-spacing: 0;
    border: 1px solid black;
    background-color: #ffffff;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function AllWariParticipants() {
  const [myData, setMyData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://31.222.200.74:8080/auth/leaderboard/getAllWariParticipants');
        setMyData(response.data);
        await db.participants.bulkPut(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        const localData = await db.participants.toArray();
        setMyData(localData);
      }
    };
    fetchData();

    const handleSync = async () => {
      const syncItems = await db.syncQueue.toArray();
      for (let item of syncItems) {
        try {
          if (item.operation === 'updateStatus') {
            await axios.patch('http://31.222.200.74:8080/auth/leaderboard/updateStatus', item.data);
          } else if (item.operation === 'updateRoom') {
            await axios.patch('http://31.222.200.74:8080/auth/leaderboard/updateRoom', item.data);
          }
          await db.syncQueue.delete(item.id);
        } catch (error) {
          console.error('Sync error:', error);
        }
      }
    };

    window.addEventListener('online', handleSync);
    return () => {
      window.removeEventListener('online', handleSync);
    };
  }, []);

  const handleStatusChange = async (row, field) => {
    const currentValue = row[field];
    const newValue = currentValue ? null : 'done';

    if (window.confirm(`Do you want to change the status of ${field} for ${row.attendeename}?`)) {
      try {
        await axios.patch('/auth/leaderboard/updateStatus', {
          bibno: row.bibno,
          field,
          value: newValue,
        });
        setMyData(prevData =>
          prevData.map(item =>
            item.bibno === row.bibno ? { ...item, [field]: newValue } : item
          )
        );
        alert('Status updated successfully.');
      } catch (error) {
        console.error('Error updating status:', error);
        await db.syncQueue.add({ operation: 'updateStatus', data: { bibno: row.bibno, field, value: newValue } });
        alert('Status update will be synced when online.');
      }
    }
  };

  const columns = React.useMemo(() => [
    {
      Header: 'Participant Info',
      columns: [
        {
          Header: 'Attendee Name',
          accessor: 'attendeename',
        },
        {
          Header: 'Mobile',
          accessor: 'mobile',
        },
        {
          Header: 'Room',
          accessor: 'room',
        },
        {
          Header: 'BIB',
          accessor: 'bibno',
        },
        {
          Header: 'Jersey',
          accessor: 'jerseysize',
        },
        {
          Header: 'Tshirt',
          accessor: 'tshirtsize',
        },
        {
          Header: 'Gender',
          accessor: 'gender',
        }
      ],
    },
    {
      Header: 'Collection Status',
      columns: [
        {
          Header: 'BIB Collect',
          accessor: 'bibcollect'
        },
        {
          Header: 'Tshirt',
          accessor: 'tshirtcollect'
        },
        {
          Header: 'Breakfast 1',
          accessor: 'breakfast1'
        },
        {
          Header: 'Breakfast 2',
          accessor: 'breakfast2'
        },
        {
          Header: 'Lunch',
          accessor: 'lunch'
        },
        {
          Header: 'Dinner',
          accessor: 'dinner'
        },
        {
          Header: 'Medal',
          accessor: 'medal'
        }
      ],
    },
  ], []);

  const data = React.useMemo(() => myData, [myData]);

  return (
    <Styles>
      <Table columns={columns} data={data} />
    </Styles>
  );
}

export default AllWariParticipants;
