import Dexie from 'dexie';

const db = new Dexie('WariParticipantsDB');

db.version(1).stores({
  participants: 'bibno,attendeename,mobile,room,jerseysize,gender,bibcollect,tshirtcollect,breakfast1,breakfast2,lunch,dinner,medal',
  syncQueue: '++id,operation,data'
});

export default db;
